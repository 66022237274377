import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HappyPayTopUp, HappyPayTopUpMethod } from '../interfaces/happy-pay.interface';
import { ToastrService } from 'ngx-toastr';

const apiUrl = environment.apiUrl + 'merchant/happy_pay_top_up';
const enumApiUrl = environment.apiUrl + 'guest/enum';

@Injectable({
  providedIn: 'root'
})
export class HappyPayTopUpService {

  happyPayTopUpMethods: BehaviorSubject<HappyPayTopUpMethod[] | null> = new BehaviorSubject<HappyPayTopUpMethod[] | null>(null);
  newHPTopupState: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }

  setNewHpTopupState(value: any) {
    this.newHPTopupState.next(value);
  }

  getNewHPTopupState() {
    return this.newHPTopupState.asObservable();
  }

  private fetchHappyPayTopUpMethods() {
    return this.http.get(enumApiUrl+'/happy_pay_top_up_method').pipe(map((res: any) => {
      const methods = res.data;
      return methods;
    })).pipe(catchError((error) => {
      return throwError(error);
    }));
  }

  getHappyPayTopUpMethods() {
    if(!this.happyPayTopUpMethods.value) {
      this.fetchHappyPayTopUpMethods().subscribe((data) => {
        this.happyPayTopUpMethods.next(data);
        return data;
      });
    }
    return this.happyPayTopUpMethods.asObservable();
  }
  
  getHappyPayTopUps(itemPerPage: number, currentPage: number = 1, dateFilter: any = null) {
    const body = {
      item_per_page: itemPerPage,
      current_page: currentPage,
      start_date: dateFilter ? dateFilter.start : null,
      end_date: dateFilter ? dateFilter.end : null,
      sorts: [{ field : "created_at", direction : "DESC" }]
    }
    return this.http.post(apiUrl+'/search', body).pipe(map((res: any) => {
      let topUps: HappyPayTopUp[] = res.data.happypay_topups;
      topUps.forEach(topUp => {
        if(topUp.created_at) topUp.formatted_created_at = this.datePipe.transform(new Date(topUp.created_at), 'dd MMM yyyy, HH:mm');
        if(topUp.updated_at) topUp.formatted_update_at = this.datePipe.transform(new Date(topUp.updated_at), 'dd MMM yyyy, HH:mm');
      });
      return { totalItems: res.data.total_items, topUps: topUps };
    })).pipe(catchError((error) => {
      return throwError(error)
    }));
  }

  getHappyPayTopUp(id: number) {
    return this.http.get(apiUrl+'/'+id).pipe(map((res: any) => {
      let topUp: HappyPayTopUp = res.data;
      if(topUp.created_at) topUp.formatted_created_at = this.datePipe.transform(new Date(topUp.created_at), 'dd MMM yyyy, HH:mm');
      if(topUp.updated_at) topUp.formatted_update_at = this.datePipe.transform(new Date(topUp.updated_at), 'dd MMM yyyy, HH:mm');
      return topUp;
    })).pipe(catchError((error) => {
      return throwError(error)
    }));
  }

  addHappyPayTopUp(newTopUp: HappyPayTopUp) {
    return this.http.post(apiUrl, newTopUp).pipe(map((res: any) => {
      this.handleSuccess('Berhasil melakukan top up happy pay');
      return res?.data;
    }));
  }

  private handleSuccess(message: string) {
    this.toastr.success(message);
  }

}
