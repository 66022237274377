import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ShippingMethod, ShopShippingMethod } from '../interfaces/shipping-method.interface';

const apiUrl = environment.apiUrl+'merchant/';

@Injectable({
  providedIn: 'root'
})
export class ShippingMethodService {

  constructor(
    private http: HttpClient,
  ) { }

  getMyShippingMethods(itemPerPage: number = 20, currentPage: number = 1) {
    return this.http.post(apiUrl+'shop_shipping_method/search', {
      item_per_page: itemPerPage,
      current_page: currentPage,
    }).pipe(map((res: any) => {
      return { totalItems: res.data.total_items, shippingMethods: res.data.shop_shipping_methods as ShopShippingMethod[] }
    }));
  }
  
  getShippingMethods(itemPerPage: number = 20, currentPage: number = 1) {
    return this.http.post(apiUrl+'shipping_method/search', {
      item_per_page: itemPerPage,
      current_page: currentPage,
    }).pipe(map((res: any) => {
      return { totalItems: res.data.total_items, shippingMethods: res.data.shipping_methods as ShippingMethod[] }
    }));
  }

  addShippingMethod(id: number) {
    return this.http.post(apiUrl+'shop_shipping_method', { shipping_method_id: id }).pipe(map((res: any) => {
      return res.data;
    }));
  }

  removeShippingMethod(id: number) {
    return this.http.delete(apiUrl+'shop_shipping_method/'+id);
  }
}
